<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Category</h3>
        <alert-message v-if="msg" :error="msg"></alert-message>
        <v-alert dense v-if="success" type="success">Category Added Successfully</v-alert>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-flex>
                    <v-text-field label="Title*" v-model="category.title" :rules="titleRules" required></v-text-field>
                    <v-text-field label="Order to shown on webstore" type="number" v-model.number="category.displayOrder"></v-text-field>
                </v-flex>
                <v-flex>
                    <v-select clearable @click.clear="category.parentCategory=null" v-model="category.parentCategory" label="Select Parent Category" :items="categories" item-text="displayName" return-object></v-select>
                </v-flex>
                <v-btn @click="show=!show" :style="theme">Add Products</v-btn>
                <app-dialog :show="show" @close="show=!show">
                    <v-autocomplete chips v-model="selectedProducts" label="Select Products" :items="products" item-text="displayName" multiple return-object></v-autocomplete>
                    <v-flex text-right>
                        <v-btn @click="show=!show">Submit</v-btn>
                    </v-flex>
                </app-dialog>
                <v-flex class="mt-4" v-if="selectedProducts.length>0">
                    <h3>Selected Products</h3>
                    <ul v-for="product in selectedProducts" :key="product.displayName">
                        <v-chip class="ma-1">{{product.displayName}}</v-chip>
                    </ul>
                </v-flex>
                <v-layout wrap>
                    <v-flex xs12 sm4 md3 class="pa-1" width="200">
                        <video-card :src="category.image.path" @close="removeImage()" :showClose="true" v-if="category.image&&category.image.mimetype.startsWith('video/')"></video-card>
                        <image-card :src="category.image.path" @close="removeImage()" :showClose="true" v-else-if="category.image"></image-card>
                    </v-flex>
                </v-layout>
                <v-flex>
                    <h4>Add Category Image</h4><br>
                    <file-upload ref="fileupload" v-model="image" label="Upload Image"></file-upload>
                </v-flex>
                <v-progress-linear v-if="progress>0&&!msg" v-model="progress" color="orange" height="25">
                    <strong>Uploading Image {{ progress }}%</strong>
                </v-progress-linear>
                <v-checkbox v-model='category.active' label="Active"></v-checkbox>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-flex text-right>
                    <v-btn class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="submit()">Save</v-btn>
                    <v-btn v-if="id==0" class="mr-4" :style="theme" :loading="loading" :disabled="loading" @click="saveAndAddMore()">Submit and Add More</v-btn>
                    <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import AppDialog from '../../../components/AppDialog.vue';
export default {
    components: { AppDialog },
    data() {
        return {
            category: this.defaultCategory(),
            image: null,
            items: [],
            id: 0,
            titleRules: [
                v => !!v || 'Title is required',
            ],
            loading: false,
            products: [],
            selectedProducts: [],
            show: false,
            redirect: true,
            success: false,
            categories: [],
            progress: 0
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.category.vendorId = this.getUserProfile().vendorId
            this.products = await this.getItem(appConstants.PRODUCTS_API+"/refList")
            this.categories = await this.getItem(appConstants.CATEGORIES_API + "/parentRefList")
            if (this.id != 0) {
                this.category = await this.getItem(appConstants.CATEGORIES_API + "/" + this.id)
                if(this.category.image==null||this.category.image==undefined)
                    this.category.updateImage=true
            }
        },
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true
                try {
                    let formData = this.convertToFormData(this.category, [this.image], 'categoryImage')
                    if (this.id == 0) {
                        await this.uploadFormData('post', formData)
                    } else {
                        await this.uploadFormData('put', formData)
                    }
                    this.updateProducts()
                    this.loading = false
                    if (this.redirect)
                        this.$router.push("/app/categories")
                    else {
                        this.success = true
                        this.$refs.form.reset()
                        this.category = this.defaultCategory()
                        this.category.vendorId = this.getUserProfile().vendorId
                        this.categories = await this.getItem(appConstants.CATEGORIES_API + "/parentRefList")
                        this.$refs.fileupload.removeFile()
                        this.selectedProducts = []
                        this.image = null
                        this.redirect = true
                        setTimeout(() => { this.success = false }, 3000)
                    }
                } catch (error) {
                    this.handleError(error)
                    this.msg = error.response.data
                    this.loading = false
                }
            }
        },
        async updateProducts() {
            this.selectedProducts.forEach(async product => {
                if (!product.category) {
                    product.category = []
                }
                let index = product.category.findIndex(rec => rec._id == this.category._id)
                if (index == -1) {
                    product.category.push(this.convertToRefModel(this.category._id, this.category.title))
                }
                await this.putItem(appConstants.UPDATE_PRODUCT_CATEGORY.replace('$id', product._id), product)
            });
        },
        saveAndAddMore() {
            this.redirect = false
            this.submit()
        },
        defaultCategory() {
            return {
                title: "",
                active: true,
                image: "",
                displayOrder: Number,
                parentCategory: undefined,
                updateImage:false
            }
        },
        async uploadFormData(method, formData) {
            var config = {
                headers: this.commonHeader().headers,
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.progress = percentCompleted
                }
            }
            try {
                if (method == 'post')
                    await this.postItem(appConstants.CATEGORIES_API, formData, config)
                else if (method == 'put')
                    await this.putItem(appConstants.CATEGORIES_API + "/" + this.category._id, formData, config)
            } catch (error) {
                throw error
            }
        },
        removeImage() {
            this.category.image = '',
            this.category.updateImage=true
        },
    }

}
</script>

<style scoped>
.format {
    padding: 1%;
}
</style>
